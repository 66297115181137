import React, { useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	ListGroup,
	Image,
	Form,
	Button,
	Card,
} from 'react-bootstrap';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../actions/cartActions';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';

const CartScreen = () => {
	const { id: productId } = useParams(); // Use useParams to get the productId
	const location = useLocation(); // Use useLocation to get the location object
	const qty = location.search
		? Number(new URLSearchParams(location.search).get('qty'))
		: 1;
	const dispatch = useDispatch();
	const navigate = useNavigate(); // Define navigate

	const cart = useSelector((state) => state.cart);
	const { cartItems = [] } = cart; // Ensure cartItems is initialized

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	const totalPrice = useMemo(() => {
		return cartItems
			.reduce(
				(acc, item) =>
					acc +
					item.qty *
						(user_currency &&
						item.currency &&
						item.currency.toLowerCase() !== user_currency
							? (
									item.price /
									conversionRates[user_currency][item.currency.toLowerCase()]
							  ).toFixed(2)
							: item.price.toFixed(2)),
				0
			)
			.toFixed(2);
	}, [cartItems, conversionRates, user_currency]);

	useEffect(() => {
		if (productId) {
			dispatch(addToCart(productId, qty));
		}
	}, [dispatch, productId, qty]);

	const removeFromCartHandler = useCallback(
		(id) => {
			dispatch(removeFromCart(id));
		},
		[dispatch]
	);

	const checkoutHandler = useCallback(() => {
		navigate('/login?redirect=shipping');
	}, [navigate]);

	const subtotal = useMemo(() => {
		return cartItems.reduce((acc, item) => acc + item.qty, 0);
	}, [cartItems]);

	return (
		<Row>
			<Col md={8}>
				<h1>Shopping Cart</h1>
				{cartItems.length === 0 ? (
					<Message>
						Your Cart is empty <Link to='/'>Go Back</Link>
					</Message>
				) : (
					<ListGroup variant='flush'>
						{cartItems.map((item) => (
							<ListGroup.Item key={item.product}>
								<Row>
									<Col md={2}>
										<Image src={item.image[0]} alt={item.name} fluid rounded />
									</Col>
									<Col md={3}>
										<Link to={`/product/${item.product}`}>{item.name}</Link>
									</Col>
									<Col md={2}>
										{user_currency &&
										item.currency &&
										item.currency.toLowerCase() !== user_currency ? (
											<NumericFormat
												value={(
													item.price /
													conversionRates[user_currency][
														item.currency.toLowerCase()
													]
												).toFixed(2)}
												displayType={'text'}
												thousandSeparator={true}
												prefix={currencies[user_currency.toUpperCase()].symbol}
											/>
										) : (
											<NumericFormat
												value={item.price ? item.price.toFixed(2) : ''}
												displayType={'text'}
												thousandSeparator={true}
												prefix={
													user_currency
														? currencies[user_currency.toUpperCase()].symbol
														: ''
												}
											/>
										)}
									</Col>
									<Col md={2}>
										<Form.Control
											as='select'
											value={item.qty}
											onChange={(e) =>
												dispatch(
													addToCart(item.product, Number(e.target.value))
												)
											}
										>
											{[...Array(item.countInStock).keys()].map((x) => (
												<option key={x + 1} value={x + 1}>
													{x + 1}
												</option>
											))}
										</Form.Control>
									</Col>
									<Col md={2}>
										<Button
											type='button'
											variant='light'
											onClick={() => removeFromCartHandler(item.product)}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</Col>
								</Row>
							</ListGroup.Item>
						))}
					</ListGroup>
				)}
			</Col>
			<Col md={4}>
				<Card>
					<ListGroup.Item variant='flush'>
						<ListGroup.Item>
							<h2>Subtotal ({subtotal}) Items</h2>
							<NumericFormat
								value={totalPrice}
								displayType={'text'}
								thousandSeparator={true}
								prefix={
									user_currency
										? currencies[user_currency.toUpperCase()].symbol
										: ''
								}
							/>
						</ListGroup.Item>
						<ListGroup.Item>
							<Button
								type='button'
								className='btn-block'
								disabled={cartItems.length === 0}
								onClick={checkoutHandler}
							>
								Proceed To Checkout
							</Button>
						</ListGroup.Item>
					</ListGroup.Item>
				</Card>
			</Col>
		</Row>
	);
};

export default CartScreen;
