import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import Message from '../components/Message';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';
import { createOrder } from '../actions/orderActions';

const PlaceOrderScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const cart = useSelector((state) => state.cart);
	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2);
	};

	const itemsPrice = useMemo(() => {
		return addDecimals(
			cart.cartItems.reduce(
				(acc, item) =>
					acc +
					(user_currency &&
					item.currency &&
					item.currency.toLowerCase() !== user_currency
						? (
								item.price /
								conversionRates[user_currency][item.currency.toLowerCase()]
						  ).toFixed(2)
						: item.price.toFixed(2)) *
						item.qty,
				0
			)
		);
	}, [cart.cartItems, conversionRates, user_currency]);

	const shippingPrice = useMemo(() => {
		return addDecimals(Number((0.28 * itemsPrice).toFixed(2)));
	}, [itemsPrice]);

	const totalPrice = useMemo(() => {
		return (Number(itemsPrice) + Number(shippingPrice)).toFixed(2);
	}, [itemsPrice, shippingPrice]);

	const placeOrderHandler = useCallback(() => {
		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				itemsPrice,
				shippingPrice,
				totalPrice,
			})
		);
		navigate('/order');
	}, [
		dispatch,
		cart.cartItems,
		cart.shippingAddress,
		cart.paymentMethod,
		itemsPrice,
		shippingPrice,
		totalPrice,
		navigate,
	]);

	return (
		<Row>
			<Col md={8}>
				<ListGroup variant='flush'>
					<ListGroup.Item>
						<h2>Shipping</h2>
						<p>
							<strong>Address:</strong>
							{cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
							{cart.shippingAddress.postalCode}, {cart.shippingAddress.country}
						</p>
					</ListGroup.Item>

					<ListGroup.Item>
						<h2>Payment Method</h2>
						<strong>Method: </strong>
						{cart.paymentMethod}
					</ListGroup.Item>

					<ListGroup.Item>
						<h2>Order Items</h2>
						{cart.cartItems.length === 0 ? (
							<Message>Your cart is empty</Message>
						) : (
							<ListGroup variant='flush'>
								{cart.cartItems.map((item, index) => (
									<ListGroup.Item key={index}>
										<Row>
											<Col md={1}>
												<Image
													src={item.image[0]}
													alt={item.name}
													fluid
													rounded
												/>
											</Col>
											<Col>
												<Link to={`/product/${item.product}`}>{item.name}</Link>
											</Col>
											<Col md={4}>
												{item.qty} x{' '}
												{user_currency &&
												item.currency &&
												item.currency.toLowerCase() !== user_currency ? (
													<NumericFormat
														value={(
															item.price /
															conversionRates[user_currency][
																item.currency.toLowerCase()
															]
														).toFixed(2)}
														displayType={'text'}
														thousandSeparator={true}
														prefix={
															currencies[user_currency.toUpperCase()].symbol
														}
													/>
												) : (
													<NumericFormat
														value={item.price ? item.price.toFixed(2) : ''}
														displayType={'text'}
														thousandSeparator={true}
														prefix={
															user_currency
																? currencies[user_currency.toUpperCase()].symbol
																: ''
														}
													/>
												)}
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
						)}
					</ListGroup.Item>
				</ListGroup>
			</Col>
			<Col md={4}>
				<Card>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Order Summary</h2>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Items</Col>
								<Col>{itemsPrice}</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Shipping</Col>
								<Col>{shippingPrice}</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Total</Col>
								<Col>{totalPrice}</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Button
								type='button'
								className='btn-block'
								disabled={cart.cartItems.length === 0}
								onClick={placeOrderHandler}
							>
								Place Order
							</Button>
						</ListGroup.Item>
					</ListGroup>
				</Card>
			</Col>
		</Row>
	);
};

export default PlaceOrderScreen;
