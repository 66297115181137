import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { listProductDetails, updateProduct } from '../actions/productActions';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import { currencies } from '../components/Currencies';
import { getAllCategoriesByCategoryId } from '../actions/categoryActions';

const ProductEditScreen = () => {
	const { id: productId } = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const location = useLocation();
	const is_edit = location.pathname ? location.pathname.split('/')[4] : '';

	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [currency, setCurrency] = useState('INR');
	const [image, setImage] = useState('');
	const [brand, setBrand] = useState('');
	const [countInStock, setCountInStock] = useState(0);
	const [description, setDescription] = useState('');
	const [uploading, setUploading] = useState(false);
	const [mainCategory, setMainCategory] = useState([]);
	const [mainCategorySelected, setMainCategorySelected] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [subCategorySelected, setSubCategorySelected] = useState([]);
	const [thirdCategory, setThirdCategory] = useState([]);
	const [thirdCategorySelected, setThirdCategorySelected] = useState([]);
	const [fourthCategory, setFourthCategory] = useState([]);
	const [fourthCategorySelected, setFourthCategorySelected] = useState([]);
	const [fifthCategory, setFifthCategory] = useState([]);
	const [fifthCategorySelected, setFifthCategorySelected] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [previousSelectedCategory, setPreviousSelectedCategory] = useState([]);
	const [previousSelectedSubcategory, setPreviousSelectedSubcategory] =
		useState([]);
	const [previousSelectedThirdcategory, setPreviousSelectedThirdcategory] =
		useState([]);
	const [previousSelectedFourthcategory, setPreviousSelectedFourthcategory] =
		useState([]);
	const [previousSelectedFifthcategory, setPreviousSelectedFifthcategory] =
		useState([]);
	const [isClear, setIsClear] = useState(0);
	const [sizes, setSizes] = useState([]);
	const [previousSizesSelected, setPreviousSizesSelected] = useState([]);
	const clothes_sizes = [
		'S',
		'M',
		'L',
		'XL',
		'XXL',
		'3XL',
		'4XL',
		'5XL',
		'6XL',
		'7XL',
	];
	const shoes_sizes = ['6', '7', '8', '9', '10'];

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;
	const product_created_at = product ? product.createdAt : null;
	const product_updated_at = product ? product.updatedAt : null;

	const productUpdate = useSelector((state) => state.productUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate;

	const getFirstLevelCategoryData = async (e) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get('/api/category/firstlevel', config);
		setMainCategory(data);
	};
	if (mainCategory.length === 0) getFirstLevelCategoryData();

	const uploadFileHandler = async (e) => {
		const files = e.target.files;
		const formData = new FormData();
		formData.append('userId', userInfo._id);

		//console.log(files);
		//console.log(...files);
		[...files].forEach((file) => {
			formData.append('image', file);
		});

		setUploading(true);

		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};
			const { data } = await axios.post('/api/upload', formData, config);
			setImage(data);
			setUploading(false);
		} catch (error) {
			console.error(error);
			setUploading(false);
		}
	};

	const firstCategorySelectedHandler = async (first_Category_selected) => {
		if (first_Category_selected.length !== 0) {
			setMainCategorySelected(first_Category_selected);

			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/secondlevel/${first_Category_selected}`,
					config
				);
				setSubCategory(data);
			} catch (error) {
				console.error(error);
			}
		} else {
			setMainCategorySelected([]);
			setPreviousSelectedCategory([]);
			setPreviousSelectedSubcategory([]);
			setPreviousSelectedThirdcategory([]);
			setPreviousSelectedFourthcategory([]);
			setPreviousSelectedFifthcategory([]);
			setSizes([]);
			setIsClear(1);
		}
	};

	const secondCategorySelectedHandler = async (second_Category_selected) => {
		if (second_Category_selected.length !== 0) {
			setSubCategorySelected(second_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/thirdlevel/${second_Category_selected}/${mainCategorySelected}`,
					config
				);
				setThirdCategory(data);
			} catch (error) {
				console.error(error);
			}
		} else {
			setSubCategorySelected([]);
			setPreviousSelectedSubcategory([]);
			setIsClear(1);
		}
	};

	const thirdCategorySelectedHandler = async (third_Category_selected) => {
		if (third_Category_selected.length !== 0) {
			setThirdCategorySelected(third_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fourthlevel/${third_Category_selected}/${subCategorySelected}`,
					config
				);
				setFourthCategory(data);
			} catch (error) {
				console.error(error);
			}
		} else {
			setThirdCategorySelected([]);
			setPreviousSelectedThirdcategory([]);
			setIsClear(1);
		}
	};

	const fourthCategorySelectedHandler = async (fourth_Category_selected) => {
		if (fourth_Category_selected.length !== 0) {
			setFourthCategorySelected(fourth_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fifthlevel/${fourth_Category_selected}/${thirdCategorySelected}`,
					config
				);
				setFifthCategory(data);
			} catch (error) {
				console.error(error);
			}
		} else {
			setFourthCategorySelected([]);
			setPreviousSelectedFourthcategory([]);
			setIsClear(1);
		}
	};

	const fifthCategorySelectedHandler = async (fifth_Category_selected) => {
		if (fifth_Category_selected.length !== 0) {
			setFifthCategorySelected(fifth_Category_selected);
		} else {
			setFifthCategorySelected([]);
			setPreviousSelectedFifthcategory([]);
			setIsClear(1);
		}
	};

	const sizesSelectedHandler = async (user_sizes_selected) => {
		if (user_sizes_selected.length !== 0) {
			setSizes(user_sizes_selected);
		} else {
			setSizes([]);
			setPreviousSizesSelected([]);
			setIsClear(1);
		}
	};

	const getCategoryId = async (e) => {
		const all_categories = {};
		try {
			if (mainCategorySelected) {
				console.log(mainCategorySelected);
				all_categories['mainCategory'] = mainCategorySelected[0];
			}
			if (subCategorySelected) {
				all_categories['subCategory'] = subCategorySelected[0];
			}
			if (thirdCategorySelected) {
				all_categories['thirdCategory'] = thirdCategorySelected[0];
			}
			if (fourthCategorySelected) {
				all_categories['fourthCategory'] = fourthCategorySelected[0];
			}
			if (fifthCategorySelected) {
				all_categories['fifthCategory'] = fifthCategorySelected[0];
			}
			console.log(all_categories);
			const { data } = await axios.get(`/api/category/getcategoryid/`, {
				params: {
					query: JSON.stringify(all_categories),
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			});
			//console.log(data);
			setCategoryId(data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchCategories = async () => {
			if (
				selectedCategories.length === 0 &&
				product.name &&
				product.categoryId
			) {
				const data = await getAllCategoriesByCategoryId(product.categoryId);

				setSelectedCategories(data);
			}
		};

		fetchCategories();
	}, [product, selectedCategories]);

	const submitHandler = (e) => {
		e.preventDefault();
		//const categoryId = parseInt(finalCategoryId);
		//console.log(categoryId);
		// adding 10% flat on all the products.
		// TODO: after creating category ID's, create rules of margin based on product category
		//const final_price = parseInt(price) + parseInt(0.25 * price);

		if (product_created_at === product_updated_at) {
			dispatch(
				updateProduct({
					_id: productId,
					name,
					price,
					currency,
					brand,
					description,
					countInStock,
					image,
					categoryId,
					sizes,
				})
			);
		} else {
			dispatch(
				updateProduct({
					_id: productId,
					//name,
					price,
					//currency,
					//brand,
					description,
					countInStock,
					//image,
					//categoryId,
					sizes,
				})
			);
		}
	};

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET });
			navigate('/admin/productlist');
		} else {
			//console.log(product.name + ' ' + product._id + ' ' + productId);
			if (!product.name || product._id !== productId) {
				//console.log('inside product details');
				dispatch(listProductDetails(productId));
			} else {
				if (!isClear) {
					//alert('in useeffect');
					//setMainCategory(all_categories.category);
					setName(product.name);
					setPrice(product.price);
					setCurrency(product.currency);
					setImage(product.image);
					setBrand(product.brand);
					setCountInStock(product.countInStock);
					setSizes(product.sizes);
					setDescription(product.description);
					if (selectedCategories[0] && product.categoryId !== 0) {
						if (
							selectedCategories[0].category &&
							previousSelectedCategory.length === 0
						) {
							setPreviousSelectedCategory([selectedCategories[0].category]);
						}
						if (
							selectedCategories[0].subCategory &&
							previousSelectedSubcategory.length === 0
						)
							setPreviousSelectedSubcategory([
								selectedCategories[0].subCategory,
							]);
						if (
							selectedCategories[0].levelThreeCategory &&
							previousSelectedThirdcategory.length === 0
						)
							setPreviousSelectedThirdcategory([
								selectedCategories[0].levelThreeCategory,
							]);
						if (
							selectedCategories[0].levelFourCategory &&
							previousSelectedFourthcategory.length === 0
						)
							setPreviousSelectedFourthcategory([
								selectedCategories[0].levelFourCategory,
							]);
						if (
							selectedCategories[0].levelFiveCategory &&
							previousSelectedFifthcategory.length === 0
						)
							setPreviousSelectedFifthcategory([
								selectedCategories[0].levelFiveCategory,
							]);
					}
				}
			}
		}
	}, [
		dispatch,
		navigate,
		productId,
		product,
		successUpdate,
		userInfo,
		mainCategory,
		selectedCategories,
		previousSelectedCategory,
		previousSelectedSubcategory,
		previousSelectedThirdcategory,
		previousSelectedFourthcategory,
		previousSelectedFifthcategory,
		is_edit,
		isClear,
	]);

	return (
		<>
			<Link to='/admin/productlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit Product</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'> {errorUpdate}</Message>}
				{}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='name'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter name'
								value={name}
								disabled={product_created_at !== product_updated_at}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='price'>
							<Form.Label>Price</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter price'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='currency'>
							<Form.Label>Choose Currency</Form.Label>
							<Form.Control
								as='select'
								value={currency}
								disabled={product_created_at !== product_updated_at}
								onChange={(e) => setCurrency(e.target.value)}
							>
								{Object.keys(currencies)
									.filter(function (item) {
										return item === 'INR'; // Applying this to stop user from changing currency as its only available in India for now.
									})
									.map((item) => {
										return (
											<option
												value={currencies[item].code}
												key={currencies[item].code}
											>
												{currencies[item].code} - {currencies[item].symbol}
											</option>
										);
									})}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='image'>
							<Form.Label>Image</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter image URL'
								value={image}
								hidden={true}
								onChange={(e) => setImage(e.target.value)}
							></Form.Control>
							<Form.Group controlId='multi-files'>
								<Form.Label>Choose File</Form.Label>
								<Form.Control
									type='file'
									accept='image/*'
									multiple
									custom
									disabled={product_created_at !== product_updated_at}
									onChange={uploadFileHandler}
								/>
							</Form.Group>
							{uploading && <Loader />}
						</Form.Group>

						<Form.Group controlId='brand'>
							<Form.Label>Brand</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter brand'
								value={brand}
								disabled={product_created_at !== product_updated_at}
								onChange={(e) => setBrand(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='countInStock'>
							<Form.Label>Count In Stock</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter Count In Stock'
								value={countInStock}
								onChange={(e) => setCountInStock(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='categories'>
							<Form.Label>Choose Category & Sub Categories</Form.Label>

							<Typeahead
								id='first-category'
								clearButton
								disabled={product_created_at !== product_updated_at}
								labelKey='category'
								onChange={firstCategorySelectedHandler}
								options={mainCategory}
								placeholder='Choose a category...'
								selected={
									mainCategorySelected.length !== 0
										? mainCategorySelected
										: previousSelectedCategory
								}
							/>
							{(subCategory[0] != null ||
								previousSelectedSubcategory.length !== 0) && (
								<Typeahead
									style={{ marginTop: '20px' }}
									id='sub-category'
									clearButton
									disabled={product_created_at !== product_updated_at}
									labelKey='subCategory'
									onChange={secondCategorySelectedHandler}
									options={subCategory}
									placeholder='Choose a sub category...'
									selected={
										subCategorySelected.length !== 0
											? subCategorySelected
											: previousSelectedSubcategory
									}
								/>
							)}

							{(thirdCategory[0] != null ||
								previousSelectedThirdcategory.length !== 0) && (
								<Typeahead
									style={{ marginTop: '20px' }}
									id='third-category'
									clearButton
									disabled={product_created_at !== product_updated_at}
									labelKey='levelThreeCategory'
									onChange={thirdCategorySelectedHandler}
									options={thirdCategory}
									placeholder='Choose a sub category...'
									selected={
										thirdCategorySelected.length !== 0
											? thirdCategorySelected
											: previousSelectedThirdcategory
									}
								/>
							)}

							{(fourthCategory[0] != null ||
								previousSelectedFourthcategory.length !== 0) && (
								<Typeahead
									style={{ marginTop: '20px' }}
									id='fourth-category'
									clearButton
									disabled={product_created_at !== product_updated_at}
									labelKey='levelFourCategory'
									onChange={fourthCategorySelectedHandler}
									options={fourthCategory}
									placeholder='Choose a sub category...'
									selected={
										fourthCategorySelected.length !== 0
											? fourthCategorySelected
											: previousSelectedFourthcategory
									}
									//onBlur={getCategoryId}
								/>
							)}

							{(fifthCategory[0] != null ||
								previousSelectedFifthcategory.length !== 0) && (
								<Typeahead
									style={{ marginTop: '20px' }}
									id='fifth-category'
									clearButton
									disabled={product_created_at !== product_updated_at}
									labelKey='levelFiveCategory'
									onChange={fifthCategorySelectedHandler}
									options={fifthCategory}
									placeholder='Choose a sub category...'
									selected={
										fifthCategorySelected.length !== 0
											? fifthCategorySelected
											: previousSelectedFifthcategory
									}
								/>
							)}
						</Form.Group>

						<Form.Group controlId='sizes'>
							<Form.Label>Size(s)</Form.Label>
							<Typeahead
								//style={{ marginTop: '20px' }}
								id='product-size'
								multiple
								clearButton
								labelKey='sizes'
								onChange={sizesSelectedHandler}
								options={
									mainCategorySelected.length !== 0 &&
									(mainCategorySelected.toString().includes('Shoes') ||
										mainCategorySelected.toString().includes('Clothes'))
										? mainCategorySelected.toString().includes('Shoes')
											? shoes_sizes
											: clothes_sizes
										: Array('N/A')
								}
								placeholder='Choose available size(s)...'
								selected={sizes.length !== 0 ? sizes : previousSizesSelected}
							/>
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as='textarea'
								rows={3}
								placeholder='Enter Description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button
							type='submit'
							variant='primary'
							onMouseEnter={
								product_created_at === product_updated_at ? getCategoryId : null
							}
						>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	);
};

export default ProductEditScreen;
