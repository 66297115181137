import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Rating from './Rating';
import { NumericFormat } from 'react-number-format';
import { currencies } from './Currencies';

const Product = ({ product }) => {
	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	var user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';
	return (
		<Card className='mb-3 rounded product product-card'>
			<Link to={`/product/${product._id}`}>
				<Card.Img
					src={product.image[0].replace('original', 'thumbnail')}
					variant='top'
				/>
			</Link>

			<Card.Body>
				<Link to={`/product/${product._id}`}>
					<Card.Title as='h6' className='title'>
						{product.name}
					</Card.Title>
				</Link>

				<Card.Text as='div'>
					<Rating
						value={product.rating}
						text={`${product.numReviews} reviews`}
					/>
				</Card.Text>

				<Card.Text>
					<b className='fs-5'>
						{product.countInStock === 0 ? (
							<span className='text-danger'>Out of Stock</span>
						) : user_currency &&
						  product.currency &&
						  product.currency.toLowerCase() !== user_currency ? (
							<NumericFormat
								value={(
									product.price /
									conversionRates[`${user_currency}`][
										`${product.currency}`.toLowerCase()
									]
								).toFixed(2)}
								displayType={'text'}
								thousandSeparator={true}
								prefix={currencies[`${user_currency}`.toUpperCase()].symbol}
							/>
						) : (
							<NumericFormat
								value={product.price.toFixed(2)}
								displayType={'text'}
								thousandSeparator={true}
								prefix={
									user_currency &&
									currencies[`${user_currency}`.toUpperCase()].symbol
								}
							/>
						)}
					</b>
				</Card.Text>
				<Card.Text as='div' className='added-by'>
					<small className='text-muted'>
						By: {product.user ? product.user.name : 'Unknown'}
					</small>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Product;
